import { Game, Vector2 } from "./game";
import { Target } from "./target";

export class Dart {
    position: Vector2;
    frames: HTMLImageElement[];
    hitSprite: HTMLImageElement;
    missSprite: HTMLImageElement;

    target: Target | null = null;

    missed: boolean = false;
    currentFrame: number = 0;

    inAir: boolean;

    constructor(x: number, y: number, frames: HTMLImageElement[], hit: HTMLImageElement, miss: HTMLImageElement, scale: number){
        this.position = {x: x, y: y};
        this.frames = frames;
        this.hitSprite = hit;
        this.missSprite = miss;
        this.inAir = true;
        this.scale = scale;
    }

    hit(target: Target){
        this.inAir = false;
        this.target = target;
        this.offset = {x: this.position.x / Game.aspectRatioWidth - this.target.position.x, y: this.position.y / Game.aspectRatioHeight - this.target.position.y}
    }

    miss(){
        this.inAir = false;
        this.missed = true;
    }

    fallingDown: number = 0;

    offset: Vector2 | undefined = undefined;
    scale: number = 1;


    render(){
        let frame: HTMLImageElement;
        if(this.currentFrame < (this.frames.length - 1) && !this.missed){
            this.currentFrame += 1;
            frame = this.frames[this.currentFrame];
        }
        else if(!this.missed) {
            frame = this.hitSprite;
        } else {
            frame = this.missSprite;
            this.fallingDown += 0.2;

            this.position.y = this.position.y + this.fallingDown * this.fallingDown;
        }

        if(this.target){
            if(this.offset === undefined){
            }
            this.position.x = this.target.position.x + this.offset.x;
            this.position.y = this.target.position.y + this.offset.y;
            const screenX = this.position.x * Game.aspectRatioWidth - (frame.width * this.scale * Game.scale) / 2;
            const screenY = this.position.y * Game.aspectRatioHeight - (frame.height * this.scale * Game.scale) / 2;
            Game.drawImage(frame, screenX, screenY, frame.width * this.scale * Game.scale, frame.height * this.scale * Game.scale);
            return;
        }

        // if(this.fallingDown > 3){
        //     this.fallingDown = 3;
        // }




        const screenX = this.position.x - (frame.width * Game.scale * this.scale) / 2;
        const screenY = this.position.y - (frame.height * Game.scale * this.scale) / 2 + this.fallingDown * this.fallingDown;
        Game.drawImage(frame, screenX, screenY, frame.width * this.scale * Game.scale, frame.height * this.scale * Game.scale);
    }
}