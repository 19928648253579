import {
	LevelConfig,
	printConfig,
	TargetConfig,
	_defaultConfig,
} from "./config";
import { Game } from "./game";

const customConfig: any = getSettingsFromURI(location.search);
let config = _defaultConfig;

if (customConfig)
	for (const [key, value] of Object.entries(customConfig)) {
		config[key] = value;
	}

const game = new Game(config);

function getSettingsFromURI(uri: string) {
	let settingString;
	const split = uri.split("&");
	for (let s of split) {
		if (s[0] === "s") {
			settingString = atob(s.split("=")[1]);
		}
	}

	if (settingString === undefined) {
		return null;
	}

	let jsonString = "{";
	let settings: string[] = settingString.split("\n");

	let levels: LevelConfig[] = [];

	settings.forEach((s) => {
		const keyAndValue = s.split("=");
		if (keyAndValue.length === 1) {
			return;
		}

		const key = keyAndValue[0].trim();
		let value = keyAndValue[1].trim();

		if (value === "default" || key === "cache") {
			return;
		}

		if (key === "level") {
			levels.push(parseLevel(value));
			return;
		}

		let line = '"' + key + '": ';
		if (isString(value)) {
			line += '"' + value + '"';
		} else {
			line += value;
		}
		if (settings.indexOf(s) < settings.length - 1) {
			line += ",";
		}

		jsonString += line;
	});
	if (levels.length > 0) {
		jsonString += '"levels":' + JSON.stringify(levels);
	}
	jsonString += "}";
	return JSON.parse(jsonString);
}

function parseLevel(level: string) {
	const timeLimit = +level.trim().split("-")[0];
	const dartScale = +level.trim().split("-")[1];
	const levelData = level.trim().split("}");
	let targets = [];
	levelData.forEach((target) => {
		const targetData = target.split("{")[1];
		if (targetData) targets.push(parseTarget(targetData));
	});
	return { timeLimit: timeLimit, targets: targets, dartScale: dartScale };
}

function parseTarget(target: string) {
	let targetData: TargetConfig = {
		position: { x: 0, y: 0 },
		sprite: 1,
	};
	const properties = target.split(";");
	properties.forEach((prop) => {
		const keyAndValue = prop.split(":");
		if (keyAndValue.length === 1) {
			return;
		}

		const key = keyAndValue[0].trim();
		let value = keyAndValue[1].trim();

		if (key === "position") {
			const xAndY = value.split(",");
			let x: number;

			if (xAndY[0].includes("%")) {
				x = 375 * (+xAndY[0].split("%")[0] / 100);
			} else {
				x = +xAndY[0];
			}

			let y: number;
			if (xAndY[1].includes("%")) {
				y = 500 * (+xAndY[1].split("%")[0] / 100);
			} else {
				y = +xAndY[1];
			}
			targetData.position = {
				x: x,
				y: y,
			};
		} else if (key === "points") {
			const points = value.split(",");
			if (points.length === 1) {
				targetData.points = +points[0];
			} else if (points.length === 2) {
				if (points[0] !== "X") targetData.points = +points[0];

				targetData.bullsEyePoints = +points[1];
			}
		} else if (key === "bullsEyeRadius") {
			targetData.bullsEyeRadius = +value;
		} else if (key === "hitRadius") {
			targetData.hitRadius = +value;
		} else if (key === "number") {
			targetData.sprite = +value;
		} else if (key === "scale") {
			const scale = value;
			targetData.scale = +scale;
		} else if (key === "hits") {
			targetData.hits = +value;
		} else if (key === "hitTemplate") {
			targetData.hitTemplate = value;
		} else {
			if (targetData.motion === undefined) {
				targetData.motion = {
					circleFrames: 0,
					radius: 0,
					startRotation: 0,
				};
			}
			targetData.motion[key] = +value;
		}
	});

	return targetData;
}

function isString(value: string) {
	if (!isNaN(Number(value)) || value === "true" || value === "false") {
		return false;
	}
	return true;
}
